
import Sidebar from '@/layout/Sidebar.vue'
const pcCommon = [
    {
        path: '/news',
        component: Sidebar,
        name: 'News',
        redirect: '/news/list',
        meta: { title: '新闻' },
        children: [
            {
                path: 'list/:newstype?',
                component: () => import('@/views/news/newsList.vue'),
                name: 'NewsList',
                meta: { title: '新闻列表' }
            },
            {
                path: 'detail/:newsid',
                name: 'NewsDetail',
                component: () => import('@/views/news/newsDetail.vue')
            },
            {
                path: 'searchlist',
                name: 'Searchlist',
                component: () => import('@/views/news/searchlist.vue')
            }
        ]
    },

    {
        path: '/commonProblem',
        component: Sidebar,
        hidden: true,
        children: [
            {
                path: ':problemtype?',
                component: () => import('@/views/otherStatic/comproblem/index.vue')
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/otherStatic/comproblem/detail.vue')
            }
        ]
    },

    {
        path: '/datadown',
        component: Sidebar,
        hidden: true,
        children: [
            {
                path: 'list',
                component: () => import('@/views/otherStatic/download/list.vue'),
                name: 'DatadownList',
                meta: { title: '相关下载' }
            },
            {
                path: 'detail/:id',
                name: 'DatadownDetail',
                meta: { title: '下载详情' },
                component: () => import('@/views/otherStatic/download/detail.vue')
            }
        ]
    },

    {
        path: '/contact',
        component: Sidebar,
        name: 'Contact',
        redirect: '/contact/contactus',
        meta: { title: '联系我们' },
        children: [
            // {
            //     path: '/aboutus',
            //     component: () => import('@/views/otherStatic/aboutus.vue'),
            //     name: 'Aboutus',
            //     meta: { title: '关于我们' }
            // },
            {
                path: '/contactus',
                component: () => import('@/views/otherStatic/contactus.vue'),
                name: 'Contactus',
                meta: { title: '联系我们' }
            }
        ]
    },
    {
        path: '/legalNotices',
        component: Sidebar,
        name: 'LegalNotices',
        redirect: '/contact/legalNotices',
        meta: { title: '联系我们' },
        children: [
            {
                path: '/legalNotices',
                component: () => import('@/views/otherStatic/legalNotices.vue'),
                name: 'LegalNotices',
                meta: { title: '法律声明' }
            }
        ]
    },

    {
        path: '/forgotPassword',
        component: Sidebar,
        meta: { title: '忘记密码' },
        children: [
            {
                path: '',
                component: () => import('@/views/otherStatic/forgotPassword.vue')
            }
        ]
    },

    {
        path: '/redirect',
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index.vue'),
                meta: { whitelist: true }
            }
        ]
    }
]

export default pcCommon
