<template>
    <div id="app">
        <el-config-provider :locale="locale">
            <router-view />
        </el-config-provider>
    </div>
</template>

<script setup>

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
const locale = zhCn

</script>

<style lang="less" scoped>

</style>

