<template>
    <div class="footer noprint">
        <div class="mid-layout row noprint">
            <div class="col v-m col-24 pore noprint">
                <div class="footer_link t-l noprint" style="    position: absolute;top: 15px;">
                    <!--                <a href="aboutUs.html" target="_blank">关于我们</a>-->
                    <a href="#/contactus">联系我们</a>
                    <!--                <a href="legalNotices.html" target="_blank">法律声明</a>-->
                    <!--                <a href="http://www.jtzyzg.org.cn/LTPU/LEAP/home/html/message.html">意见箱</a>-->
                </div>
                <div class="  table foot_copy noprint" style="position: relative;margin-top:62px;">
                    <div class=" info" style="color: white;text-align:left;" v-if="islongrise">
                        <span class="col col-12">版权所有：鹏城工匠职业能力发展（深圳）有限责任公司</span>
                        <span class="col col-12" >信息维护：鹏城工匠职业能力发展（深圳）有限责任公司</span>

                        <!-- <span class="col col-12" >备案编号：京ICP备14012828号</span> -->
                        <!--                    <span class="col col-12">技术客服热线电话： 027-87027339</span>-->
                        <!--<span class="col col-12" style="padding-left:26px;">责任编辑：尚迪</span>-->
                    </div>
                    <div class=" info" style="color: white;text-align:left;" v-else>
                        <span class="col col-12">版权所有：广东交通职业技术学院</span>
                        <span class="col col-12" >信息维护：广东交通职业技术学院</span>

                        <!-- <span class="col col-12" >备案编号：京ICP备14012828号</span> -->
                        <!--                    <span class="col col-12">技术客服热线电话： 027-87027339</span>-->
                        <!--<span class="col col-12" style="padding-left:26px;">责任编辑：尚迪</span>-->
                    </div>
                </div>
                <div class="row mt-20 poab noprint" style="right:70px;top:15px;font-size: 16px;">
                    <p class="v-m mb-5 mb-20" >网站浏览次数统计</p>
                    <p class="foot_count_num num01  v-m">
                        <span v-text="el" v-for="el in pvList" :key="el.id"></span>
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>

import { request } from '@/utils/Tool'
import { computed } from 'vue'

const pvList = reactive(['-', '-', '-', '-', '-', '-', '-', '-', '-'])

onMounted(() => {
    request('getPvNumFromRedis', {
        'subsite': import.meta.env.VITE_APP_OUTPUTPATH,
        'cloumn': '0101',
        'isAddPv': true
    }).then(() => {
    }).catch(() => {
    })

    request('getPvNumFromRedis', {
        'subsite': import.meta.env.VITE_APP_OUTPUTPATH,
        'isAddPv': false
    }).then((result) => {
        if (result) {
            var num = result.result
            if (num < 2000) {
                num = parseInt(num) + 1000
            }
            if (num / 10 > 0) { // 个位
                pvList[8] = parseInt(num % 10)
            }
            if (num / 100 > 0) { // 十位
                pvList[7] = parseInt(num % 100 / 10)
            }
            if (num / 1000 > 0) { // 百位
                pvList[6] = parseInt(num % 1000 / 100)
            }
            if (num / 10000 > 0) { // 千位
                pvList[5] = parseInt(num % 10000 / 1000)
            }
            if (num / 100000 > 0) { // 万位
                pvList[4] = parseInt(num % 100000 / 10000)
            }
            if (num / 1000000 > 0) { // 十万位
                pvList[3] = parseInt(num % 1000000 / 100000)
            }
            if (num / 10000000 > 0) { // 百万位
                pvList[2] = parseInt(num % 10000000 / 1000000)
            }
            if (num / 1000000 > 0) { // 千万位
                pvList[1] = parseInt(num % 100000000 / 10000000)
            }
            if (num / 10000000 > 0) { // 亿位
                pvList[0] = parseInt(num % 1000000000 / 100000000)
            }
        }
    }).catch(() => {})

    //强制刷新方法
    // request('forceRefreshPageViewRedis', {
    //     'subsite': import.meta.env.VITE_APP_OUTPUTPATH,
    //     'key': 'taidtaxiexamrest',
    // })
})

const islongrise = computed(() => {
    if (import.meta.env.VITE_APP_OUTPUTPATH == 'aggd') {
        return false
    } else {
        return true
    }
})
</script>

<style lang="less" scoped>
  /*footer*/
  .footer{min-width: 1400px !important;text-align:center;display: block; width: 100%;height:165px; background: @footercolor;color: #fff;padding:20px 0 0 0;
    img{width: 100px;}
    .footer_link {
      a{
        display: inline-block;font-size: 16px;color: #fff;margin-right: 65px;
        &:hover{color: #e2b208;-webkit-transition: .3s;transition: .3s;}
      }
    }
    .foot_copy{
      .info{color:#a7c3e7;width:70%;border-top:1px solid #fff;padding: 20px 0; box-sizing:border-box;
        >span{display: inline-block; width:375px;text-align: left;font-size: 14px;margin-bottom: 5px;}
      }
    }
    .foot_count_num {
      &.num01{float: left;display: inline-block;padding: 0px 5px; border-radius: 50%;color:#fff;}
      span{border-radius: 3px;background: #ed3e10;color: #fff;text-align: center;padding: 0 4px; font-size: 16px;margin-right: 2px;margin-right: 7px}
    }
  }
</style>
