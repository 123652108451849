<template>
    <div class="noprint headerNav">
        <div class="header" style="position: relative;">
            <img :src="'https://download.yxybb.com/project/ZYJY/Exam/image/'+envConfig+'_banner.png'" alt="" style="width: 100%">
        </div>

        <div class="nav-bar">
            <ul class="mid-layout row h">

                <li class="col col-18 v-m">
                    <div class="nav w h">
                        <a class="v-m"
                           :class="{ 'active': route.currentRoute.value.fullPath == '/index' }"
                           href="#/index">首页</a>
                        <a class="v-m"
                           :class="{ 'active': route.currentRoute.value.fullPath.indexOf('/signup') > -1 }"
                           href="#/signup/index">考试报名</a>
                        <a class="v-m"
                           :class="{ 'active': route.currentRoute.value.fullPath.indexOf('/publicQuery') > -1 }"
                           href="#/publicQuery">公共查询</a>
                        <a class="v-m"
                           :class="{ 'active': route.currentRoute.value.fullPath == '/commonProblem' }"
                           href="#/commonProblem">常见问题</a>
                        <a class="v-m"
                           :class="{ 'active': route.currentRoute.value.fullPath == '/contactus' }"
                           href="#/contactus">联系我们</a>
                    </div>
                </li>
                <li class="col col-6 v-m" v-show="route.currentRoute.value.fullPath == '/index'">
                    <div class="search hide">
                        <input type="password" style="display: none" />
                        <input id="keyword" type="text" style="color: #FFF;" v-model.trim="keyword" placeholder="请输入关键字" />
                        <button type="button"
                                class="iconfont icon-sousuo "
                                style="font-size: 24px;"
                                @click="buttonshow($event)"></button>
                    </div>
                </li>
                <li class="col col-6 v-m" v-show="route.currentRoute.value.fullPath != '/index'">
                    <div class="log_reg clear" v-if="!userBean">
                        <div @click="popbox_show()" id="log_box">
                            登录/注册
                        </div>
                    </div>
                    <div class="log_reg clear" v-if="userBean">
                        <div v-if="userBean">
                            <p style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                                您好，<span v-text="userBean.showname"></span>
                            </p>
                            <ul v-if="userBean.persontype == '2'" style="right: 15px">
                                <li style="line-height: 45px"><a class="link_a" href="#/entity/gallery">单位信息</a></li>
                                <li style="line-height: 45px" @click="logout()"><a href="javascript:void(0);" style="font-size: 15px">退出登录</a></li>
                            </ul>
                            <ul v-else style="right: 15px">
                                <li style="line-height: 45px"><a class="link_a" href="#/person/base">个人中心</a></li>
                                <li style="line-height: 45px"><a class="link_a" href="#/myExam/audit">我的审核</a></li>
                                <li style="line-height: 45px"><a class="link_a" href="#/myExam/exam">我的考试</a></li>
                                <li v-if="isCert" style="line-height: 45px"><a class="link_a" href="#/myExam/cert">我的证书</a></li>
                                <li style="line-height: 45px" @click="logout()"><a href="javascript:void(0);"
                                                                                   style="font-size: 15px">退出登录</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <LoginPopup @close="close()" v-if="isshow"></LoginPopup>

    </div>
</template>

<script setup>
import LoginPopup from '#/components/LoginPopup/index.vue'

import { ref, inject, computed } from 'vue'
import { alertMsg, confirmMsg } from '@/hooks/globle'
import { useUser_2 } from '@/store/modules/puser'
import { useRouter } from 'vue-router'
const $jquery = inject('$jquery')
const route = useRouter()
const store = useUser_2()
const isCert = Number(import.meta.env.VITE_SHOW_CERT)

const isshow = ref(false)
const keyword = ref('')
const userBean = computed(() => useUser_2().userBean)
const envConfig = import.meta.env.VITE_APP_OUTPUTPATH
function logout() {
    confirmMsg('是否确认退出登录？', () => {
        store.logout().then(() => {
            window.location.href = '#/index'
        })
    })
}

function popbox_show() {
    isshow.value = true
}

function close() {
    isshow.value = false
}

function buttonshow(e) {
    $jquery(e.target).parent().toggleClass('hide')
    if (keyword.value) {
        sessionStorage.setItem('keyword', keyword.value)
        keyword.value = ''
        window.location.href = '#/news/searchlist'
    }
}

</script>
<style lang="less" scoped>
.headerNav {
    min-width: 1400px;
}

/*header*/
.nav-bar {
    height: 48px;
    background-color: @syscolor;
    margin-top: -5px
}

.nav a {
    margin-right: 5px;
    display: inline-block;
    width: 123px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    position: relative;
    text-align: center;
    transition: 0.3s;
    height: 48px;
    line-height: 48px;
    padding: 0 1em;
}

.nav a:hover {
    background: @navbg;
}

.nav a:before {
    content: "|";
    font-size: 10px;
    position: absolute;
    left: -2px;
    color: @syscolor;
    font-weight: 100;
}

.nav a:first-child:before {
    content: ""
}

.nav .active {
    font-weight: bold;
    background: @navbg;
}

.search {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 35px;
}

.search input {
    display: inline-block;
    background: @syscolor;
    border: 1px solid #fff;
    height: 35px;
    line-height: 35px;
    padding: 0 1em;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
}

.search .iconfont {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    display: inline-block;
    width: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    cursor: pointer;
    height: 35px;
    transition: 0.3s;
}

.search .iconfont:hover {
    opacity: 0.9;
}

.search input::-webkit-input-placeholder {
    color: #fff;
}

.search.hide input {
    width: 0;
    padding: 0;
    border: none;
}

.search.hide button {
    background: #F7BB1B;
    border-radius: 50%;
}</style>
