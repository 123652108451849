<template>
    <section class="view">
        <router-view v-slot="{ Component }">
            <transition name="fade-transform" mode="out-in" appear>
                <!--                      <keep-alive :exclude="exclude" :max="10">-->
                <keep-alive :include="include">
                    <component :is="Component" :key="key"/>
                </keep-alive>
            </transition>
        </router-view>
    </section>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const exclude = ['index', 'forgotPassword', 'base', 'utilp', 'standard']
const include = ['newIndex', 'staging'] // 禁止 index (多个文件 name 一致)

const key = computed(() => {
    if (route.name === 'recruitDetail' || route.name === 'NewsDetail') { // 适配新闻详情、招聘公告页面，直接更换路由参数，达到类似刷新效果
        return route.path
    } else {
        return route.name
    }
})
</script>

<style scoped lang=less>
    .view{
        min-height: 520px;
    }
</style>
