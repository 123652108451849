import { createRouter, createWebHashHistory } from 'vue-router'
import Sidebar from '@/layout/Sidebar.vue'

/* Router Modules */
import pcCommon from './modules/common'
import personCenter from '#/router/pc/personCenter'
import signup from '#/router/pc/signup'

/** 常驻路由 */
export const routes = [
    // {
    //     path: '/404',
    //     component: () => import('@/views/error/404.vue'),
    //     alias: '/:pathMatch(.*)*'
    // },
    {
        path: '/',
        name: 'index',
        redirect: '/index',
        component: () => import('#/views/index.vue')
    },
    {
        path: '/index',
        component: Sidebar,
        meta: { title: '首页' },
        children: [
            {
                path: '',
                name: 'Index',
                component: () => import('#/views/index.vue')
            }
        ]
    },
    ...pcCommon,
    ...personCenter,
    ...signup
    // {
    //     path: '/:pathMatch(.*)*', // Must put the 'Error' route at the end, 必须将 'Error' 路由放在最后
    //     redirect: '/404',
    //     name: 'Error'
    // }
]
//

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const asyncRoutes = []

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // 每次切换路由页面滚动到顶部，浏览器后退/前进除外
    scrollBehavior(to, from, savedPosition) {
        // 待定，若有多个页面无需滚回顶部则根据 to 、 from 各自的 meta 参数综合判断
        const flag = to.name === from.name
        if (!flag) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { top: 0 }
            }
        }
    }
})

export default router
