<template>
    <div class="loginmask">
        <!--登陆注册-->
        <div class="login_box pop_box">
            <div class="pop_close" @click="shutdown()"></div>
            <div class="login_con">
                <ul class="log_list clear">
                    <li :class="{'cur':loginshow}"  @click="togglechange('login')">登录</li>
                    <li :class="{'cur':!loginshow}"  @click="togglechange('register')">注册</li>
                </ul>
                <div class="login_list">
                    <div class="login_dis" v-if="loginshow">
                        <div class="log_left"><img src="@/assets/image/log_left.jpg"></div>
                        <div class="log_right form">
                            <div class="reg_list loginIcon">
                                <div>
                                    <span class="iconfont icon-yonghuming"></span>
                                    <input type="text"
                                           id="logname"
                                           autocomplete="off"
                                           placeholder="请输入用户名或证件号"
                                           @blur="checkIsRequire($event.currentTarget.id)"
                                           v-model.trim="logname"
                                           @focus="initInput($event.currentTarget.id)"/>
                                </div>
                                <p class="log_tip" id="lognameTips" v-show="tipsList.lognameTips">{{tipsList.lognameTips}}</p>
                            </div>

                            <div class="reg_list loginIcon" >
                                <div>
                                    <span class="iconfont icon-mima"></span>
                                    <label class="pwd-lab" style="left: 69px"></label>
                                    <input type="password"
                                           id="logpwd"
                                           autocomplete="off"
                                           placeholder="请输入密码"
                                           @blur="checkIsRequire($event.currentTarget.id)"
                                           v-model.trim="logpwd"
                                           @focus="initInput($event.currentTarget.id)" />
                                </div>
                                <p class="log_tip" id="logpwdTips" v-show="tipsList.logpwdTips">{{tipsList.logpwdTips}}</p>
                            </div>
                            <div class="reg_list loginIcon" >
                                <div>
                                    <span class="iconfont icon-yanzhengma"></span>
                                    <input type="text"
                                           id="logcode"
                                           autocomplete="off"
                                           placeholder="请输入验证码"
                                           maxlength="4"
                                           style="width: 70%"
                                           @blur="checkIsRequire($event.currentTarget.id)"
                                           v-model.trim="logcode"
                                           @focus="initInput($event.currentTarget.id)" />
                                    <span class="iconfont icon-yanzhengma"></span>
                                    <div id="checkCode" readonly="readonly" v-html="codeInfo" @click="getCodeImg()"></div>
                                </div>
                                <p class="log_tip" id="logcodeTips" v-show="tipsList.logcodeTips">{{tipsList.logcodeTips}}</p>
                            </div>
                            <ul class=" c-6 fs-14 w index-inp pop-ksxt clear">
                                <li style="line-height: 23px;">登入系统</li>
                                <li>
                                    <label for="n1">
                                        <input type="radio" id="n1" name="n" v-model="logsys" value="1">
                                        <span>个人</span>
                                    </label>
                                </li>
                                <li v-if="isUnit">
                                    <label for="n2">
                                        <input type="radio" id="n2" name="n" v-model="logsys" value="2">
                                        <span>单位</span>
                                    </label>
                                </li>
                                <li class="">
                                    <label for="n3">
                                        <input type="radio" id="n3" name="n" v-model="logsys" value="3">
                                        <span>考试考务</span>
                                    </label>
                                </li>
                            </ul>
                            <p style="font-size: 14px;margin-top: 10px">
                                <!--<a class="link" style="color: #fa3131;cursor: pointer" @click="examPoint()">考生报名指南</a>-->
                                <span class="log_sec" style="cursor: pointer" @click="forgotpwd()">忘记密码？</span>
                            </p>
                            <a class="login_btn btn_unclick" href="javascript:;" @keydown.enter="keyDown()" @click="login('ispop')">立即登录</a>
                            <h2 class="c-3 fs-16 mt-20" style="font-size: 15px">登录说明：</h2>
                            <p class="tip" style="font-size: 14px;margin-top: 5px">绑定证件号后可用证件号登录</p>
                        </div>

                    </div>
                    <div class="login_dis" v-else="!loginshow">
                        <div class="log_left"><img src="@/assets/image/log_left.jpg"></div>
                        <div class="log_right reg_right form">
                            <div class="reg_list">
                                <div class="regItem">
                                    <span class="iconfont icon-yonghuming"></span>
                                    <input type="text"
                                           id="regname"
                                           placeholder="请输入用户名"
                                           v-model.trim="regname"
                                           @blur="checkRegName()"
                                           @focus="getTips('regname')"/>
                                    <p class="reg_tip" v-html="regnameTips"></p>
                                </div>
                            </div>
                            <div class="reg_list" style="position: relative">
                                <div class="regItem">
                                    <span class="iconfont icon-mima"></span>
                                    <label class="pwd-lab" style="left: 41px"></label>
                                    <input type="password"
                                           id="regpwd"
                                           placeholder="请设置密码"
                                           v-model.trim="regpwd"
                                           @blur="checkRegPwd()"
                                           @focus="getTips('regpwd')"/>
                                    <p class="reg_tip" id="regpwdTips" v-html="regpwdTips"></p>
                                </div>

                            </div>
                            <div class="reg_list" style="position: relative">
                                <div class="regItem">
                                    <span class="iconfont icon-mima"></span>
                                    <label class="pwd-lab" style="left: 41px"></label>
                                    <input type="password"
                                           id="regconfpwd"
                                           placeholder="请确认密码"
                                           v-model.trim="regconfirmpwd"
                                           @blur="checkRegConfirmPwd()"/>
                                    <p class="reg_tip" id="regconfpwdTips" v-text="regconfirmpwdTips"></p>
                                </div>

                            </div>
                            <div class="reg_list">
                                <div class="regItem">
                                    <span class="iconfont icon-shouji" style="font-size: 24px;font-weight: bold;"></span>
                                    <input type="text"
                                           id="regphone"
                                           placeholder="请输入手机号"
                                           v-model.trim="regphone"
                                           @blur="checkRegPhone()"/>
                                    <p class="reg_tip" id="regphoneTips" v-text="regphoneTips"></p>
                                </div>

                            </div>
                            <div class="reg_list">
                                <div class="regItem">
                                    <span class="iconfont icon-yanzhengma"></span>
                                    <input type="text"
                                           style="width: 60%"
                                           id="phonecode"
                                           placeholder="请输入验证码"
                                           v-model.trim="phonecode"
                                           @focus="init()"
                                           @blur="checkPhoneCode()"/>
                                    <a class="reg_yzm on" href="javascript:;" v-text="timertext" @click="getRegPhoneCode()" id="timer"></a>
                                    <p class="reg_tip" id="phonecodeTips" v-html="phonecodeTips"></p>
                                </div>

                            </div>
                            <a class="reg_btn" href="javascript:;" @click="register()" id="regusername">立即注册</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>

import { ref, reactive, onMounted, computed } from 'vue'
import { alertMsg, confirmMsg } from '@/hooks/globle'
import { checkPhone, decryptByDESqas, whczcRequest } from '@/utils/Tool'
import { loginLEAP } from '@/utils/LEAPLogin'
import { getUserByUsername, personRegister } from '#/api/common/user'
import { checkPhoneCodeAsPhone, sendShortMessage } from '#/api/common/sendMessage'
// import {useStore} from "vuex";
import { useUser_2 } from '@/store/modules/puser'
// import { defineStore } from 'pinia'
const store = useUser_2()
const isUnit = Number(import.meta.env.VITE_SHOW_UNIT)

const emit = defineEmits(['close'])
const props = defineProps({
    isShow: Boolean
})

const loginshow = ref(true)
const userBean = computed(() => store.userBean)

// 公共
const logsys = ref(1)
const vericode = ref('')

// 登录
const logname = ref('')
const logpwd = ref('')
const logcode = ref('')
const codeInfo = ref('')
const tipsList = reactive({
    lognameTips: '',
    logpwdTips: '',
    logcodeTips: ''
})

// 注册
const regname = ref('')
const regpwd = ref('')
const regconfirmpwd = ref('')
const regphone = ref('')
const phonecode = ref('')

const nametipscolor = ref('#666')
const pwdtipscolor = ref('#666')
const regnameTips = ref("<span style='color:#999999'>用户名为6~18位且首位不能为数字</span>")
const regpwdTips = ref("<span style='color:#999999'>密码由8~18位字母、数字或下划线组成</span>")
const regconfirmpwdTips = ref('')
const regphoneTips = ref('')
const phonecodeTips = ref('')

const regFlag = ref(true)
const regnamelegal = ref(false)
const phonecodeflag = ref(false)
const checkPhoneVerifiCode = ref(false)
const phoneHasChange = ref(false)
const phonecodeissend = ref(false) // 验证码是否已发送
const isOverdue = ref(true)
const timertext = ref('免费获取验证码')
const inter = ref('')
const Tips = ref('')
const phonebinded = ref('')
const isblacklist = ref(false)
// 点击回车键登录
const keyDown = (e) => {
    if (e.keyCode == 13 || e.keyCode == 100) {
        login()
    }
}

// 跳转至后台管理平台页面回退时，页面未能刷新问题
window.addEventListener('pageshow', function(e) {
    if (e.persisted) {
        window.location.reload()
    }
})

onMounted(() => {
    getCodeImg()
    window.addEventListener('keydown', keyDown)
})

function checkIsRequire(id) {
    let flag = true
    let msg = ''

    if (id == 'logname' && !logname.value) {
        flag = false
        msg = '请输入用户名或证件号'
    }
    if (id == 'logpwd' && !logpwd.value) {
        flag = false
        msg = '请输入密码'
    }

    if (flag) {
        tipsList[id + 'Tips'] = ''
        return true
    } else {
        tipsList[id + 'Tips'] = msg
        return false
    }
}

function initInput(id) {
    if (id == 'regpwd' || id == 'regname') {
        var rtips = '用户名为6~18位且首位不能为数字'
        if (id == 'regpwd') {
            rtips = '密码由8~18位字母、数字或下划线组成'
            pwdtipscolor.value = '#666'
        } else {
            nametipscolor.value = '#666'
        }
        tipsList[id + 'Tips'] = rtips
    } else {
        tipsList[id + 'Tips'] = ''
    }
}
function getCodeImg() {
    var selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']// 所有候选组成验证码的字符，当然也可以用中文的
    var elementChar = ['font', 'i', 'b']
    var code = ''
    var codeLength = 4// 验证码的长度
    var html = ''
    for (var i = 0; i < codeLength; i++) {
        var charIndex = Math.floor(Math.random() * 33)
        code += selectChar[charIndex]
        var r = parseInt(Math.random() * 155)
        var g = parseInt(Math.random() * 155)
        var b = parseInt(Math.random() * 155)
        var e = parseInt(Math.random() * 3)
        var s = parseInt(Math.random() * 10) + 18
        var color = 'rgb(' + r + ',' + g + ',' + b + ')'
        html += '<' + elementChar[e] + " style='font-size:" + s + 'px;color: ' + color + "'>" +
              selectChar[charIndex] + '</' + elementChar[e] + '>&nbsp;'
    }
    codeInfo.value = html
    vericode.value = code.toUpperCase()
}
function checkLogcode() {
    if (!logcode.value) {
        tipsList.logcodeTips = '请输入验证码'
    } else {
        var cval = logcode.value
        if (cval.toUpperCase() == vericode.value) {
            tipsList.logcodeTips = ''
            return true
        } else {
            tipsList.logcodeTips = '验证码错误'
        }
    }
    return false
}

function dealisOverdue() {
    const isOverdue = sessionStorage.getItem('isOverdue')
    if (isOverdue) {
        if (isOverdue == 'true') {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

// 登录
function login() {
    if (checkIsRequire('logname') && checkIsRequire('logpwd') && checkLogcode()) {
        if (logsys.value == 1 || logsys.value == 2) {
            store.login({
                'username': logname.value,
                'password': logpwd.value,
                'persontype': logsys.value
            }).then((result) => {
                if (result) {
                    var resultstate = result.resultstate
                    if (resultstate == 1) {
                        if (result.result.persontype == 2) {
                            window.location.href = '#/entity/gallery'
                            shutdown()
                        } else {
                            window.location.reload()
                        }
                    } else if (result.resultstate == 9) {
                        const url = window.location.href.split('/LEAP')[0] + '/LEAP/'
                        let V = result.result
                        if (import.meta.env.VITE_APP_OUTPUTPATH == 'agqg') {
                            V = V.replaceAll('TIADExam', 'SGDWExam')
                        }
                        window.location.href = url + V
                    } else {
                        tipsList.lognameTips = result.resultdesc
                        getCodeImg()
                    }
                }
            }).catch((backData) => {
                tipsList.lognameTips = backData.resultdesc
                getCodeImg()
            })
        } else if (logsys.value == 3) {
            let V = loginLEAP('SGDW', logname.value, logpwd.value, 'Exam_WebLogin')
            console.log(V)
            if (V != null && V.indexOf('.html') != -1) {
                var path = location.protocol + '//' + location.host + '/' + location.pathname.split('/')[1] + '/'
                if (import.meta.env.VITE_APP_OUTPUTPATH == 'agqg') {
                    V = V.replaceAll('TIADExam', 'SGDWExam')
                }
                window.parent.location.href = path + V
            } else {
                tipsList.lognameTips = '用户名或密码错误'
            }
        }
    }
}
function logout() {
    confirmMsg('是否确认退出登录？', () => {
        store.logout()
        window.location.href = '#/index'
    })
}
// 注册
function togglechange(type) {
    if (type == 'login') {
        loginshow.value = true
        regnameTips.value = "<span style='color: #999999'>用户名为6~18位且首位不能为数字</span>"
        regpwdTips.value = "<span style='color: #999999'>密码由8~18位字母、数字或下划线组成</span>"
        regphoneTips.value = ''
        phonecodeTips.value = ''
        regconfirmpwdTips.value = ''

        regname.value = ''
        regpwd.value = ''
        regconfirmpwd.value = ''
        regphone.value = ''
        phonecode.value = ''
        setTimeout(() => {
            getCodeImg()
        })
    } else {
        loginshow.value = false
        for (const k in tipsList) {
            tipsList[k] = ''
        }
        console.log(tipsList)
        logname.value = ''
        logpwd.value = ''
        logcode.value = ''
    }
}
function init() {
    phonecodeTips.value = ''
}
function getTips(ele) {
    if (ele == 'regname') {
        regnameTips.value = "<span style='color: #999999'>用户名为6~18位且首位不能为数字</span>"
    } else {
        regpwdTips.value = "<span style='color: #999999'>密码由8~18位字母、数字或下划线组成</span>"
    }
}
function checkRegName() {
    var regnameVal = regname.value
    var regNameExp = /^[\u4E00-\u9FA5a-zA-Z_][\u4E00-\u9FA5a-zA-Z0-9_]{6,18}$/

    return new Promise((resolve, reject) => {
        nametipscolor.value = '#ff0000'
        if (regnameVal != null && regnameVal.trim() != '') {
            if (regnameVal.length > 18) {
                regnameTips.value = '用户名过长，请重新输入'
                reject()
                return
            } else if (regnameVal.length < 6) {
                regnameTips.value = '用户名长度不足，请重新输入'
                reject()
                return
            }
            if (regnameVal.match(regNameExp)) {
                var str = regnameVal.replace(/[^\x00-\xff]/g, 'xx')
                if (str.length < 6) {
                    regnameTips.value = '用户名长度不足，请重新输入'
                    reject()
                    return
                } if (str.length > 18) {
                    regnameTips.value = '用户名过长，请重新输入'
                    reject()
                    return
                } else {
                    if (!regFlag.value) {
                        reject()
                        return
                    }

                    getUserByUsername(regnameVal).then(() => {
                        regnameTips.value = '用户名已存在'
                        reject()
                    }).catch(() => {
                        regnameTips.value = ''
                        regnamelegal.value = true
                        nametipscolor.value = '#666'
                        resolve()
                    }).finally(() => {
                        regFlag.value = true
                    })
                }
            } else {
                regnameTips.value = '用户名存在不符规则的字符，请重新输入'
                reject()
            }
        } else {
            regnameTips.value = '请输入用户名'
            reject()
        }
    }).then(() => {

    }).catch(() => {
        return
    })
}
function checkRegPwd() {
    var password = regpwd.value
    pwdtipscolor.value = '#ff0000'
    if (password != null && password.trim() != '') {
        if (password.length < 8 || password.length > 18) {
            regpwdTips.value = '密码长度为8~18位'

            return false
        } else {
            var pwdExp = /((?=.*[A-Za-z])(?=.*\d)|(?=[A-Za-z])(?=.*[#@!~%^&*+_-])|(?=.*\d)(?=.*[#@!~%^&*+_-])){8,18}/i
            if (password.match(pwdExp)) {
                regpwdTips.value = ''
                pwdtipscolor.value = '#666'
                return true
            } else {
                regpwdTips.value = '密码至少包含数字或字母符号中两种组合，长度8~18位'
                return false
            }
        }
    } else {
        regpwdTips.value = '请设置密码'
        return false
    }
}
// 验证确认密码
function checkRegConfirmPwd() {
    var regconfirmpwdVal = regconfirmpwd.value
    var pwd = regpwd.value
    if (pwd == null || pwd == '') {
        regconfirmpwdTips.value = '请先输入密码'
        return false
    } else {
        if (regconfirmpwdVal != null && regconfirmpwdVal.trim() != '') {
            if (pwd == regconfirmpwdVal) {
                regconfirmpwdTips.value = ''
                return true
            } else {
                regconfirmpwdTips.value = '两次输入密码不一致'
                return false
            }
        } else {
            regconfirmpwdTips.value = '请输入确认密码'
            return false
        }
    }
}
function checkRegPhone() {
    var phone = regphone.value
    if (phone) {
        if (checkPhone(phone)) {
            if (phoneHasChange.value) {
                checkPhoneCode()
                return false
            }
            regphoneTips.value = ''
            isblacklist.value = true
            return true
        } else {
            regphoneTips.value = '手机号码格式错误'
            isblacklist.value = false
            return false
        }
    } else {
        regphoneTips.value = '请输入手机号码'
        isblacklist.value = false
        return false
    }
}
// 验证手机验证码
function checkPhoneCode() {
    isOverdue.value = dealisOverdue()
    var phonecodeval = phonecode.value
    var phone = regphone.value
    if (phonecodeval == '' || phonecodeval == null) {
        phonecodeTips.value = '请输入验证码'
        checkPhoneVerifiCode.value = false
        return false
    } else if (phonecodeissend.value && isOverdue.value) {
        phonecodeTips.value = '验证码已失效，请重新获取'
        checkPhoneVerifiCode.value = false
        return false
    } else if (!phonebinded.value) {
        phonecodeTips.value = '请先获取验证码'
        checkPhoneVerifiCode.value = false
        return false
    } else {
        checkPhoneCodeAsPhone(phone, phonecodeval).then((returnData) => {
            if (returnData.resultstate == 0) {
                var correctEncrypt = decryptByDESqas(returnData.resultdesc)
                if (correctEncrypt.indexOf('true') > -1 && correctEncrypt.indexOf(phone) > -1) {
                    phonecodeTips.value = ''
                    phoneHasChange.value = false
                    checkPhoneVerifiCode.value = true
                    return true
                } else {
                    phonecodeTips.value = '验证码错误'
                    checkPhoneVerifiCode.value = false
                    return false
                }
            } else {
                phonecodeTips.value = returnData.resultdesc
                checkPhoneVerifiCode.value = false
                return false
            }
        }).catch(() => {
            phonecodeTips.value = '验证码错误'
            checkPhoneVerifiCode.value = false
            return false
        })
    }
}
// 获取手机验证码
function iscodevalid() {
    var times = 300
    inter.value = setInterval(function() {
        times--
        if (times <= 0) {
            sessionStorage.removeItem('isOverdue')
            window.clearInterval(inter.value)
        }
    }, 1000)
}
function getRegPhoneCode() {
    if (checkRegPhone() && checkRegPwd() && checkRegConfirmPwd()) {
        if (!phonecodeflag.value) {
            var phone = regphone.value
            if (isblacklist.value) {
                isOverdue.value = dealisOverdue()
                const data = {
                    'phone': phone,
                    'isOverdue': isOverdue.value,
                    'sys': 0
                }
                phonebinded.value = phone
                phonecodeflag.value = true
                sessionStorage.setItem('isOverdue', 'false')

                sendShortMessage(data).then((res) => {
                    iscodevalid()
                    phonecodeissend.value = true
                    var time = 60
                    inter.value = setInterval(function() {
                        time--
                        timertext.value = time + 's后重新获取'
                        if (time <= 0) {
                            window.clearInterval(inter.value)
                            timertext.value = '重新获取验证码'
                            phonecodeflag.value = false
                        }
                    }, 1000)
                }).catch(() => {
                    phonecodeflag.value = false
                })

                // var phoneLegal = {
                //     'bean': {
                //         'phone': phone,
                //         'isfirst': true// 是否验证手机号注册过
                //     }
                // }
                // whczcRequest('checkPhoneIsIllegal', phoneLegal).then((result) => {
                //     if (result) {
                //         var phoneResult = result
                //         if (phoneResult.resultstate == 1) {

                //         } else if (phoneResult.resultstate == 0) {
                //             regphoneTips.value = phoneResult.resultdesc
                //         }
                //     }
                // })
            }
        }
    }
}
function register() {
    checkRegPwd()

    Promise.all([checkRegName()]).then(() => {
        // 当用户名可用
        if (regnamelegal.value) {
            if (checkRegPwd() && checkRegConfirmPwd() && checkRegPhone()) {
                var phonecodeval = phonecode.value
                if (phonecodeval == '' || phonecodeval == null) {
                    phonecodeTips.value = '请输入验证码'
                    checkPhoneVerifiCode.value = false
                    return
                }
                if (!checkPhoneVerifiCode.value) {
                    return
                }
                if (phonebinded.value == regphone.value) {
                    if (regFlag.value) {
                        regFlag.value = false
                        getUserByUsername(regname.value).then((result) => {
                            Tips.value = '用户名已存在'
                            regFlag.value = true
                        }).catch(() => {
                            var data = {
                                'username': regname.value,
                                'password': regpwd.value,
                                'phonenum': regphone.value,
                                'sys': '7'
                            }
                            // 注册
                            personRegister(data).then((regResult) => {
                                if (regResult) {
                                    if (regResult.resultstate == 1) {
                                        store.getUser()
                                        alertMsg('注册成功，请先完善个人信息！', () => {
                                            window.location.href = '#/person/base'
                                        }, 'success')
                                    } else {
                                        alertMsg(regResult.resultdesc, () => {}, 'error')
                                    }
                                } else {
                                    alertMsg('注册失败，请稍后重试', () => {}, 'error')
                                }
                                window.setTimeout(function() {
                                    regFlag.value = true
                                }, 200)
                            })
                        })
                    } else {
                        alertMsg('请勿多次重复提交注册信息', () => {}, 'warning')
                    }
                } else {
                    Tips.value = '提交手机号与校验手机号不一致，请重新获取验证码'
                }
            } else {

            }
        }
    })
}

function shutdown() {
    emit('close')
}

function forgotpwd() {
    shutdown()
    window.location.href = '#/forgotPassword'
}
function examPoint() {
    window.open('https://download.yxybb.com/project/LTPU/images/kyllr/document/OperationDocument_PC.pdf')
}

</script>

<style lang="less" scoped>
  .loginmask{
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .login_box {
    margin: 0;
    display: block;
    position: fixed;
    _position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    _bottom: auto;
    box-sizing: border-box;
    background: #fff;
    width: 680px;
    height: 500px;
    overflow: hidden;
  }
  .form input:focus {
    transform: translate(0,0);
    box-shadow:none;
  }

  .log_tip{
    font-size: 12px;
    color: red;

  }

  .login_dis{
    display: block;
  }
  .login_box .reg_list .iconfont{
    line-height: 40px;
  }
  .reg_btn {
    background: @syscolor2;
    width: 317px;
    height: 43px;
    display: block;
    color: #fff;
    text-align: center;
    line-height: 43px;
    font-size: 16px;
    margin-right: 30px;
    border-radius: 5px;
    margin-top: 20px;
    border: 0;
  }
  .reg_btn:hover{
    opacity: 0.9;

  }
  .reg_list{
    height: 56px;
  }

  .log_user>div,.reg_list>div{
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  .reg_list>div .iconfont{
    position: absolute;
  }

  .reg_list.loginIcon .iconfont{
    border-right: 1px solid #B5C3DF;

  }
  .form .reg_list.loginIcon input{
    padding-left: 50px;
  }

  .form input,.login_box .form .iconfont{
    transition: none!important;
  }

  .reg_list p.reg_tip{
    color: red !important;
  }

  .log_right p{
    padding: 0;
    overflow: hidden;
  }

  .regItem{
    position: relative;
  }
  .regItem .reg_tip{
    position: absolute;
    top: 40px;
  }

  #checkCode {
    height: 40px;
    width: 104px;
    float: right;
    line-height: 40px;
    background-color: #fbecc9;
    text-align: center;
    cursor: pointer;
  }
</style>
