
import Sidebar from '@/layout/Sidebar.vue'
import person from '#/layout/person.vue'

const signup = [
    {
        path: '/signup',
        component: Sidebar,
        name: 'signup',
        redirect: '/signup/index',
        meta: { title: '报名' },
        children: [
            {
                path: 'index',
                name: 'SelectPlan',
                component: () => import('#/views/signup/index.vue')
            },
            {
                path: 'promise',
                name: 'Promise',
                component: () => import('#/views/signup/promise.vue')
            },
            {
                path: 'selectSubject',
                name: 'SelectSubject',
                component: () => import('#/views/signup/selectSubject.vue')
            },
            {
                path: 'confirmInfo',
                name: 'ConfirmInfo',
                component: () => import('#/views/signup/confirmInfo.vue')
            },
            {
                path: 'registrationCheck/:planid',
                name: 'RegistrationCheck',
                component: () => import('#/views/signup/registrationCheck.vue')
            },
            {
                path: 'printAdmissionTicket/:printid',
                name: 'PrintAdmissionTicket',
                component: () => import('#/views/signup/printAdmissionTicket.vue')
            },
            {
                path: 'printScore/:printid',
                name: 'printScore',
                component: () => import('#/views/signup/printScore.vue')
            },

        ]
    },
    {
        path: '/myExam',
        component: person,
        redirect: '/myExam/audit',
        name: 'Person',
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: 'audit',
                component: () => import('#/views/exam/myAudit.vue'),
                name: 'Audit'
            },
            {
                path: 'exam',
                component: () => import('#/views/exam/myExam.vue'),
                name: 'Exam'
            },
            {
                path: 'score',
                component: () => import('#/views/exam/myScore.vue'),
                name: 'Score'
            },
            {
                path: 'cert',
                component: () => import('#/views/exam/myCert.vue'),
                name: 'Cert'
            }
        ]
    },
    {
        path: '/myCert',
        component: Sidebar,
        redirect: '/myCert/certDetail/:certid',
        name: 'cert',
        meta: {
            title: '我的证明'
        },
        children: [
            {
                path: 'certDetail/:certid',
                component: () => import('#/views/exam/certDetail.vue'),
                name: 'CertDetail'
            }
        ]
    },

    // {
    //     path: '/myCert',
    //     component: Sidebar,
    //     redirect: '/myCert/certDetail/:certid',
    //     name: 'Person',
    //     meta: {
    //         title: '我的证明'
    //     },
    //     children: [
    //         {
    //             path: 'certDetail/:certid',
    //             component: () => import('#/views/exam/certDetail.vue'),
    //             name: 'CertDetail'
    //         }
    //
    //     ]
    // },
    // {
    //     path: '/paytype/:parameter',
    //     name: 'paytype',
    //     meta: { title: '支付验证' },
    //     component: () => import('#/views/pufapay/checktype.vue')
    // },
    // {
    //     path: '/pay',
    //     component: Sidebar,
    //     name: 'pay',
    //     meta: { title: '支付页' },
    //     children: [
    //         {
    //             path: ':orderid',
    //             name: 'qcode',
    //             component: () => import('#/views/pufapay/index.vue')
    //         }
    //     ]
    // },
    {
        path: '/publicQuery',
        component: Sidebar,
        name: 'PublicQuery',
        redirect: '/publicQuery/room',
        meta: { title: '公共查询' },
        children: [
            {
                path: ':querytype?',
                name: 'PublicQuery',
                component: () => import('#/views/publicQuery/queryIndex.vue')
            }
        ]
    },
    {
        path: '/search',
        component: Sidebar,
        name: 'search',
        redirect: '/publicQuery/room',
        meta: { title: '公共查询' },
        children: [
            {
                path: ':querytype?',
                name: 'search',
                component: () => import('#/views/publicQuery/queryIndex.vue')
            }
        ]
    }
]

export default signup
