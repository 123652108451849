import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import './router/permission'
// import { request, cutout, hasfosystem } from '@/utils/Tool'
import { cutout } from '@/utils/Tool'
import { truncate, nullreplace, obfuscation, subTime16, subTime10 } from '@/hooks/filter'
import installIcon from '@/icons/index'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import '@/assets/fonts/iconfont.css'
// import '@/style/reset.less'
import 'swiper/swiper.min.css'
// import '@/style/element-plus.scss' // element-plus主题样式修改或覆盖
import '@/style/rest.css'
import '@/style/main.less'

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App)
document.body.addEventListener('plugin_web_update_notice', (e) => {
    const { version, options } = e.detail
    // write some code, show your custom notification and etc.
    alert('已发现新版本，即将刷新页面！')
    window.location.reload(true)
})
// 使用 globalProperties 将全局变量附加到 Vue 实例上，便于在模板部分可直接用
app.config.globalProperties.truncate = truncate
app.config.globalProperties.nullreplace = nullreplace
app.config.globalProperties.obfuscation = obfuscation
app.config.globalProperties.subTime16 = subTime16
app.config.globalProperties.subTime10 = subTime10
app.config.globalProperties.cutout = cutout
// app.config.globalProperties.hasfosystem = hasfosystem
// 使用 provide 将全局变量作为响应式对象，便于在js部分可直接用
app.use(pinia)
// app.provide('$request', request)
app.provide('$jquery', $)
app.use(installIcon) // 注册全局图标
// app.use(store)
app.use(router)
app.mount('#app')
