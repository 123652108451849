import router from '../router'
import NProgress from 'nprogress'
import { storeToRefs } from 'pinia'
import { useUser, useUser_2,useUser_tjsw } from '@/store/modules/puser'

NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
    const sys = import.meta.env.VITE_APP_PROJECT
    if (sys == 'whczc') {
        useUser().getuserBean
        NProgress.start()
        next()
    }else if(sys == 'tjsw'){
        useUser_tjsw().getuserBean
        NProgress.start()
        next()
    } else {
        const store = useUser_2()
        store.renewUserBean() // 更新 localStorage 状态
        const { userBean } = storeToRefs(store) // 从 Store 中提取属性同时保持其响应式
        const user = userBean.value
        const navigationType = performance.getEntries()[0].type // 一个表示导航类型的字符串，取值为：navigate (通过网址导航过来)、reload (点击'刷新'页面)、back_forware (通过'后退'方式来到此页面)、prerender (预渲染)
        const arr = ['person', 'entity', 'registration', 'signup', 'myExam']
        let whitelist = true
        arr.forEach(item => {
            if (to.path.includes(item) && !to.path.includes('signup/index')) {
                whitelist = false
            }
        })
        // 1.NProgress 开始
        NProgress.start()
        // 2.动态设置标题
        // const title = import.meta.env.VITE_APP_PLATFORM
        // document.title = to.meta.title ? `${title} - ${to.meta.title}` : title
        // 3.判断是访问登陆页，有 userBean 就在当前页面，没有 userBean 重置路由到登陆页
        if (user) {
            // 判断是如何导航到当前文档，如果为'刷新'且处于非白名单页面，就重新获取一次用户信息
            if (navigationType === 'reload') {
                store.getUser().then(result => {
                    if (result) {
                        next()
                    } else {
                        next({ path: '/', replace: true })
                        NProgress.done()
                    }
                })
            } else {
                next()
            }
        } else if (whitelist) {
            next()
        } else {
            // 其他没有访问权限的页面将被重定向到登录页面
            if (from.path == '/index') next({ path: '/redirect/index', replace: true })
            else next({ path: '/', replace: true })
            // next({ path: '/', replace: true })
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
