<template>
    <div  class="app-wrapper">
        <Header />
        <div style="width: 1200px;margin: 0 auto;min-height:700px;padding-top:30px">
            <div class="cell center_l" >
                <div class="bg_fa bor_ed" >
                    <div class="t-c" style="padding-top: 30px">
                        <p class="head_img mt_40">
                            <a class="edit_img" href="javascript:;" >
                                <img style="width: 100%;height: 100%;" :src="cutout()+userBean.photosrc" v-if="userBean && userBean.photosrc"/>
                                <img style="width: 100%;height: 100%;" src="@/assets/image/personalphoto.jpg" v-else/>
                            </a>
                        </p>
                        <p class="mt_20" v-if="userBean">
                            <a v-text="userBean.loginusername" v-if="!userBean.personname" class="sub_tit name_hover" style="height: 30px;line-height: 30px;padding-left: 0px"></a>
                            <a v-text="userBean.personname" v-else class="sub_tit name_hover" style="height: 30px;line-height: 30px;padding-left: 0px"></a>
                        </p>
                    </div>
                    <div class="side_nav mt_40">
                        <div class="side_has_sub" :class="{'on' :leftNavState=='showBas'}">
                            <a class="side_nav_btn bas"
                               :class="{'on' :leftNavState=='showBas'}"
                               style="position: relative"
                               href="javascript:;"
                               @click="changeTab($event)">
                                <span class="iconfont icon-yonghuming"></span><span style="font-weight: 600">个人资料</span><i></i>
                            </a>
                            <div class="side_sub_nav " :class="{'on' :leftNavState=='showBas'}">
                                <a href="#/person/base" :class="{'on' :route.fullPath=='/person/base'}">基本信息</a>
                                <a href="javascript:;" @click="clickNext('#/person/extra')"  :class="{'on' :contrast('extra')}">报名信息</a>

                                <a href="javascript:;" @click="clickNext('#/person/experience')"  :class="{'on' :route.fullPath=='/person/experience'}">工作信息</a>
                            </div>
                        </div>
                        <div class="side_has_sub" :class="{'on' :leftNavState=='showPost'}">
                            <a class="side_nav_btn exam"
                               :class="{'on' :leftNavState=='showPost'}"
                               style="position: relative"
                               href="javascript:;"
                               @click="changeTab($event)">
                                <span class="iconfont icon-kaoshi1"></span><span style="font-weight: 600">业务办理</span><i></i>
                            </a>
                            <div class="side_sub_nav"  :class="{'on' :leftNavState=='showPost'}">
                                <a @click="clickNext('#/myExam/audit')" :class="{'on' :route.fullPath=='/myExam/audit'}" style="font-size: 15px">我的审核</a>
                                <a @click="clickNext('#/myExam/exam')" :class="{'on' :route.fullPath=='/myExam/exam'}" style="font-size: 15px">我的考试</a>
                                <a @click="clickNext('#/myExam/score')" :class="{'on' :route.fullPath=='/myExam/score'}" style="font-size: 15px">我的成绩单</a>
                                <a v-if="isCert" @click="clickNext('#/myExam/cert')"  :class="{'on' :route.fullPath=='/myExam/cert'}" style="font-size: 15px">我的证书</a>
                            </div>
                        </div>
                        <div class="side_has_sub" v-if="isEdu">
                            <a href="https://wuhan.yxybb.com/TEDU/LEAP/sgdw/index.html" class="side_nav_btn util modify" :class="{'on' :leftNavState == 'showEdu'}" style="position: relative">
                                <span class="iconfont icon-setting-copy-copy"></span><span style="font-weight: 600">继续教育</span><i></i></a>
                        </div>
                        <div v-if="false" class="side_has_sub" :class="{'on' :leftNavState=='showReg'}">
                            <a class="side_nav_btn exam"
                               :class="{'on' :leftNavState=='showReg'}"
                               style="position: relative"
                               href="javascript:;"
                               @click="changeTab($event)">
                                <span class="iconfont icon-kaoshi1"></span><span style="font-weight: 600">证书管理</span><i></i>
                            </a>
                            <div class="side_sub_nav"  :class="{'on' :leftNavState=='showReg'}">
                                <a  @click="clickNext('#/registration/regContinue')" :class="{'on': contrast('regContinue')}" style="font-size: 15px">证书延续</a>
                                <a  @click="clickNext('#/registration/regChange')" :class="{'on': contrast('regChange')}" style="font-size: 15px">证书变更</a>
                                <a  @click="clickNext('#/registration/regTransfer')" :class="{'on': contrast('regTransfer')}" style="font-size: 15px">受聘单位调动</a>
                                <a  @click="clickNext('#/registration/regLogout')" :class="{'on': contrast('regLogout')}" style="font-size: 15px">证书注销</a>
                            </div>
                        </div>
                        <div class="side_has_sub" >
                            <a href="#/person/utilp" class="side_nav_btn util modify" :class="{'on' :leftNavState == 'showUtilp'}" style="position: relative">
                                <span class="iconfont icon-setting-copy-copy"></span><span style="font-weight: 600">账号设置</span><i></i></a>
                        </div>

                        <div class="side_has_sub">
                            <a href="javascript:void('0')"  class="side_nav_btn util modify" style="position: relative;cursor: pointer">
                                <span class="iconfont icon-logout"></span><span style="font-weight: 600" @click="logout()">退出登录</span><i></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell center_r">
                <app-main />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script setup>
import AppMain from '@/layout/AppMain.vue'
import Header from '#/layout/Header.vue'
import Footer from '#/layout/Footer.vue'

import { computed, inject, onMounted, ref, watch } from 'vue'
import { cutout } from '@/utils/Tool'
import { useUser_2 } from '@/store/modules/puser'
import { alertMsg, confirmMsg } from '@/hooks/globle'
import { useRoute } from 'vue-router'
const route = useRoute()
const $jquery = inject('$jquery')
const store = useUser_2()
const leftNavState = ref('')
const userBean = computed(() => useUser_2().userBean)
const isEdu = Number(import.meta.env.VITE_SHOW_EDU)
const isCert = Number(import.meta.env.VITE_SHOW_CERT)
console.log(isCert)

// const haveStudy = ref(true)

watch(() => route.fullPath, (newpath) => {
    tabchange(newpath)
})

onMounted(() => {
    tabchange(route.fullPath)
    // if (import.meta.env.VITE_APP_OUTPUTPATH != 'aghb') {
    //     haveStudy.value = false
    // }
})

function tabchange(path) {
    if (path.includes('/person')) {
        if (path.includes('/utilp')) leftNavState.value = 'showUtilp'
        else leftNavState.value = 'showBas'
    } else if (path.includes('/myExam')) {
        leftNavState.value = 'showPost'
    } else if (path.includes('/registration')) {
        leftNavState.value = 'showReg'
    }
}

function changeTab(e) {
    var that = e.currentTarget
    if (!$jquery(that).hasClass('on')) {
        $jquery(that).siblings().slideDown()
        $jquery(that).addClass('on')
    } else {
        $jquery(that).siblings().slideUp()
        $jquery(that).removeClass('on')
    }
}
function clickNext(data) {
    var result = store.userBean
    if (result) {
        if (result.cardno) {
            window.location.href = data
        } else {
            alertMsg('请先完善基本信息', () => {}, 'warning')
        }
    } else {
        window.location.href = '#/index'
    }
}

// 路由对比
function contrast(data) {
    return route.fullPath.includes(data)
}

function logout() {
    confirmMsg('是否确认退出登录？', () => {
        store.logout().then(() => {
            window.location.href = '#/index'
        })
    })
}
</script>

<style lang="less" scoped>
.side_nav_btn.on{
    color: #234aa2;
}
.side_sub_nav a:hover, .side_sub_nav a.on {
    color: #234aa2;
    background: #ceddff;
}

@media print {
    .center_l{
        display: none;
    }
}

</style>
