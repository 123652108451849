
import person from '#/layout/person.vue'
import Sidebar from '@/layout/Sidebar.vue'

const personCenter = [
    {
        path: '/person',
        component: person,
        redirect: '/person/base',
        name: 'PersonCenter',
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: 'base',
                component: () => import('@/views/person/base/index.vue'),
                name: 'Base'
            },
            {
                path: 'extra/:type?',
                component: () => import('#/views/base/extra.vue'),
                name: 'Extra'
            },
            {
                path: 'experience',
                component: () => import('#/views/base/experience.vue'),
                name: 'Experience'
            },
            {
                path: 'utilp',
                component: () => import('@/views/person/utilp/index.vue'),
                name: 'utilp'
            }

        ]
    },
    {
        path: '/entity',
        component: Sidebar,
        redirect: '/entity/base',
        children: [
            // {
            //     path: 'base',
            //     component: () => import('#/views/entity/base.vue'),
            //     name: 'EntityBase',
            //     meta: { title: '基本信息' }
            // },
            {
                path: 'gallery',
                component: () => import('#/views/entity/gallery.vue'),
                name: 'EntityGallery'
            }

        ]
    },
    {
        path: '/registration',
        component: person,
        redirect: '/registration/regInit',
        name: 'Registration',
        meta: {
            title: '注册登记'
        },
        children: [
            {
                path: 'regContinue/:nav?',
                component: () => import('#/views/registration/regContinue.vue'),
                name: 'RegContinue'
            },
            {
                path: 'regChange/:nav?',
                component: () => import('#/views/registration/regChange.vue'),
                name: 'RegChange'
            },
            {
                path: 'regTransfer/:nav?',
                component: () => import('#/views/registration/regTransfer.vue'),
                name: 'RegTransfer'
            },
            {
                path: 'regLogout/:nav?',
                component: () => import('#/views/registration/regLogout.vue'),
                name: 'RegLogout'
            }

        ]
    }
]

export default personCenter
