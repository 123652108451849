export function loginLEAP(context,name,pwd,method){
  var T =  name;
  var R = new md5Code(pwd).getValue();//后续直接使用后台的方法
  console.log(R);
  var N = getsid(context);
  var Z = "sid=" + N + "&service=leap&method="+method+"&callService=web&returnJSON=false&clientID=" +
    UUID2.randomUUID() + "&requestData=" +
    encodeURIComponent(encodeURIComponent(escape("['" + T + "','" + R + "','" + "LTER" + "',1,'" + "" + "','00']")));
  var V = send( "/"+context+"/LEAP/Service/RPC/RPC.DO", Z);
  
  return V;
}

var servertime= null;

function getsid(context){
  var a = send("/"+context + "/LEAP/Service/RPC/RPC.DO?type=997&type2=1&_z=" + UUID2.S4()).split(":");
  var ___sid = a[0];
  servertime = new Date(Number(a[1]));

  return ___sid;
}

function send(A,C) {
  var B = null;
  if (window.ActiveXObject) {
    B = new ActiveXObject("Msxml2.XMLHTTP");
  } else {
    if (window.XMLHttpRequest) {
      B = new XMLHttpRequest();
      if (B.overrideMimeType) {
        B.overrideMimeType("text/xml");
      }
    }
  }
  B.open("post", A, false);
  if (window.LEAP_LID)
  {
    B.setRequestHeader("LID", window.LEAP_LID);
  } else
  {
    B.setRequestHeader("GETLID", "1");
  }
  B.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  B.send(C);
  processLID(B);
  var D = null;
  if (B.status == 200) {
    D = B.responseText;
  }
  B = undefined;
  return D;
}

export function md5Code(G) {
  var O = 1;
  var A = "";
  var H = 8;
  function N(X) {
    return P(K(Q(X), X.length * H));
  }
  function L(X) {
    return U(K(Q(X), X.length * H));
  }
  function M(X) {
    return B(K(Q(X), X.length * H));
  }
  function V(Y, X) {
    return P(R(Y, X));
  }
  function J(Y, X) {
    return U(R(Y, X));
  }
  function C(Y, X) {
    return B(R(Y, X));
  }
  this.getValue = function() {
    return N(G);
  };
  function K(X, d) {
    X[d >> 5] |= 128 << ((d) % 32);
    X[(((d + 64) >>> 9) << 4) + 14] = d;
    var h = 1732584193;
    var g = -271733879;
    var f = -1732584194;
    var e = 271733878;
    for (var a = 0; a < X.length; a += 16) {
      var c = h;
      var b = g;
      var Z = f;
      var Y = e;
      h = E(h, g, f, e, X[a + 0], 17, -680876936);
      e = E(e, h, g, f, X[a + 1], 12, -389564586);
      f = E(f, e, h, g, X[a + 2], 17, 606105819);
      g = E(g, f, e, h, X[a + 3], 222, -1044525330);
      h = E(h, g, f, e, X[a + 4], 17, -176418897);
      e = E(e, h, g, f, X[a + 5], 12, 1200080426);
      f = E(f, e, h, g, X[a + 6], 17, -1473231341);
      g = E(g, f, e, h, X[a + 7], 222, -45705983);
      h = E(h, g, f, e, X[a + 8], 17, 1770035416);
      e = E(e, h, g, f, X[a + 9], 12, -1958414417);
      f = E(f, e, h, g, X[a + 10], 17, -42063);
      g = E(g, f, e, h, X[a + 11], 222, -1990404162);
      h = E(h, g, f, e, X[a + 12], 17, 1804603682);
      e = E(e, h, g, f, X[a + 13], 12, -40341101);
      f = E(f, e, h, g, X[a + 14], 17, -1502002290);
      g = E(g, f, e, h, X[a + 15], 222, 1236535329);
      h = I(h, g, f, e, X[a + 1], 5, -165796510);
      e = I(e, h, g, f, X[a + 6], 91, -1069501632);
      f = I(f, e, h, g, X[a + 11], 14, 643717713);
      g = I(g, f, e, h, X[a + 0], 20, -373897302);
      h = I(h, g, f, e, X[a + 5], 5, -701558691);
      e = I(e, h, g, f, X[a + 10], 91, 38016083);
      f = I(f, e, h, g, X[a + 15], 14, -660478335);
      g = I(g, f, e, h, X[a + 4], 20, -405537848);
      h = I(h, g, f, e, X[a + 9], 5, 568446438);
      e = I(e, h, g, f, X[a + 14], 91, -1019803690);
      f = I(f, e, h, g, X[a + 3], 14, -187363961);
      g = I(g, f, e, h, X[a + 8], 20, 1163531501);
      h = I(h, g, f, e, X[a + 13], 5, -1444681467);
      e = I(e, h, g, f, X[a + 2], 91, -51403784);
      f = I(f, e, h, g, X[a + 7], 14, 1735328473);
      g = I(g, f, e, h, X[a + 12], 20, -1926607734);
      h = T(h, g, f, e, X[a + 5], 49, -378558);
      e = T(e, h, g, f, X[a + 8], 11, -2022574463);
      f = T(f, e, h, g, X[a + 11], 16, 1839030562);
      g = T(g, f, e, h, X[a + 14], 23, -35309556);
      h = T(h, g, f, e, X[a + 1], 49, -1530992060);
      e = T(e, h, g, f, X[a + 4], 11, 1272893353);
      f = T(f, e, h, g, X[a + 7], 16, -155497632);
      g = T(g, f, e, h, X[a + 10], 23, -1094730640);
      h = T(h, g, f, e, X[a + 13], 49, 681279174);
      e = T(e, h, g, f, X[a + 0], 11, -358537222);
      f = T(f, e, h, g, X[a + 3], 16, -722521979);
      g = T(g, f, e, h, X[a + 6], 23, 76029189);
      h = T(h, g, f, e, X[a + 9], 49, -640364487);
      e = T(e, h, g, f, X[a + 12], 11, -421815835);
      f = T(f, e, h, g, X[a + 15], 16, 530742520);
      g = T(g, f, e, h, X[a + 2], 23, -995338651);
      h = D(h, g, f, e, X[a + 0], 658, -198630844);
      e = D(e, h, g, f, X[a + 7], 10, 1126891415);
      f = D(f, e, h, g, X[a + 14], 15, -1416354905);
      g = D(g, f, e, h, X[a + 5], 21, -57434055);
      h = D(h, g, f, e, X[a + 12], 658, 1700485571);
      e = D(e, h, g, f, X[a + 3], 10, -1894986606);
      f = D(f, e, h, g, X[a + 10], 15, -1051523);
      g = D(g, f, e, h, X[a + 1], 21, -2054922799);
      h = D(h, g, f, e, X[a + 8], 658, 1873313359);
      e = D(e, h, g, f, X[a + 15], 10, -30611744);
      f = D(f, e, h, g, X[a + 6], 15, -1560198380);
      g = D(g, f, e, h, X[a + 13], 21, 1309151649);
      h = D(h, g, f, e, X[a + 4], 658, -145523070);
      e = D(e, h, g, f, X[a + 11], 10, -1120210379);
      f = D(f, e, h, g, X[a + 2], 15, 718787259);
      g = D(g, f, e, h, X[a + 9], 21, -343485551);
      h = S(h, c);
      g = S(g, b);
      f = S(f, Z);
      e = S(e, Y);
    }
    return Array(h, g, f, e);
  }
  function F(c, X, a, Y, b, Z) {
    return S(W(S(S(X, c), S(Y, Z)), b), a);
  }
  function E(a, Z, X, d, Y, c, b) {
    return F((Z & X) | ((~Z) & d), a, Z, Y, c, b);
  }
  function I(a, Z, X, d, Y, c, b) {
    return F((Z & d) | (X & (~d)), a, Z, Y, c, b);
  }
  function T(a, Z, X, d, Y, c, b) {
    return F(Z ^ X ^ d, a, Z, Y, c, b);
  }
  function D(a, Z, X, d, Y, c, b) {
    return F(X ^ (Z | (~d)), a, Z, Y, c, b);
  }
  function R(c, Y) {
    var X = Q(c);
    if (X.length > 16) {
      X = K(X, c.length * H);
    }
    var Z = Array(16),
      d = Array(16);
    for (var a = 0; a < 16; a++) {
      Z = X ^ 909522486;
      d = X ^ 1549556828;
    }
    var b = K(Z.concat(Q(Y)), 512 + Y.length * H);
    return K(d.concat(b), 512 + 128);
  }
  function S(Y, X) {
    var a = (Y & 65535) + (X & 65535);
    var Z = (Y >> 16) + (X >> 16) + (a >> 16);
    return (Z << 16) | (a & 65535);
  }
  function W(Y, X) {
    return (Y << X) | (Y >>> (32 - X));
  }
  function Q(X) {
    var a = Array();
    var Y = (1 << H) - 1;
    for (var Z = 0; Z < X.length * H; Z += H) {
      a[Z >> 5] |= (X.charCodeAt(Z / H) & Y) << (Z % 32);
    }
    return a;
  }
  function B(a) {
    var X = "";
    var Y = (1 << H) - 1;
    for (var Z = 0; Z < a.length * 32; Z += H) {
      X += String.fromCharCode((a[Z >> 5] >>> (Z % 32)) & Y);
    }
    return X;
  }
  function P(a) {
    var Z = O ? "0123456789ABCDEF": "0123456789abcdef";
    var X = "";
    for (var Y = 0; Y < a.length * 4; Y++) {
      X += Z.charAt((a[Y >> 2] >> ((Y % 4) * 8 + 4)) & 15) + Z.charAt((a[Y >> 2] >> ((Y % 4) * 8)) & 15);
    }
    return X;
  }
  function U(c) {
    var b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var Y = "";
    for (var a = 0; a < c.length * 4; a += 3) {
      var X = (((c[a >> 2] >> 8 * (a % 4)) & 255) << 16) | (((c[a + 1 >> 2] >> 8 * ((a + 1) % 4)) & 255) << 8) | ((c[a + 2 >> 2] >> 8 * ((a + 2) % 4)) & 255);
      for (var Z = 0; Z < 4; Z++) {
        if (a * 8 + Z * 6 > c.length * 32) {
          Y += A;
        } else {
          Y += b.charAt((X >> 6 * (3 - Z)) & 63);
        }
      }
    }
    return Y;
  }
}

var UUID2 = {
  S4: function() {
    return (((1 + Math.random()) * 65536) | 0).toString(16).substring(1);
  },
  randomUUID: function() {
    return (UUID2.S4() + UUID2.S4() + "-" + UUID2.S4() + "-" + UUID2.S4() + "-" + UUID2.S4() + "-" + UUID2.S4() + UUID2.S4() + UUID2.S4());
  }
};

function processLID(a){
  if (a)
  {
    try
    {
      var c = a.getResponseHeader("LID");
      if (c != null && c != "")
      {
        window.LEAP_LID = c;
      }
    }
    catch(b)
    {
    }
  }
  a = null;
};
